import React, { useState, useEffect } from 'react'
import Grid from "@material-ui/core/Grid";
import {CircularProgress, createStyles, makeStyles} from "@material-ui/core";
import {HubDetailCard} from './HubDetailCard';
import * as firebase from "firebase/app";

const useStyles = makeStyles(() =>
    createStyles( {
    root: {
        marginTop: 16,
    },
    card: {

    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
})
);

export interface BalenaInfo {
    project: string;
    state: string;
}

export interface Hub {
    id: string;
    name: string;
    claimedByName: string;
    claimedByAvatar: string;
    ipAddress?: string;
    balena?: BalenaInfo;
}

interface Props {
}

export const HubsPage: React.FC<Props> = () => {
    const firestore = firebase.app().firestore();
    const [hubs, setHubs] = useState<Hub[]>([]);
    const [loading, isLoading] = useState<boolean>(true);
    const classes = useStyles();
    const auth = firebase.auth().currentUser;
    const uid = auth ? auth.uid : "";
    useEffect(() => {
        const fetchData = async () => {
            isLoading(true);
            const hubs: Hub[] = [];
            try {
                const result = await firestore.collection('/Hub').where('claimedBy.uid', '==', uid).get();
                for (let i = 0; i < result.docs.length; ++i) {
                    const hubData = result.docs[i].data();
                    hubs.push({
                        id: hubData["balenaDeviceId"],
                        name: "" + hubData["userGivenName"],
                        claimedByName: hubData.claimedBy.displayName,
                        claimedByAvatar: hubData.claimedBy.avatarURL,
                        ipAddress: hubData.ipAddress,
                        balena: hubData.balena,
                    });
                }
            } catch (e) {
                console.log(e);
            }
            setHubs(hubs);
            isLoading(false);
        };
        fetchData();
    }, [uid, firestore]);

    return (
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.root}>
                <Grid item xs={11} lg={7}>
                    {hubs.map((hub, id) => <HubDetailCard key={id} hub={hub}/>)}
                    { loading && <CircularProgress size={22}/> }
                    { !loading && <a href={"https://devices-gridvis-cloud-bd455.firebaseapp.com/"}>Manage your devices</a>}
                </Grid>
            </Grid>
        )
};
