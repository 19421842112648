import React from 'react'
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {createStyles, makeStyles, Typography} from "@material-ui/core";
import { Hub } from './HubsPage'
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(() =>
    createStyles( {
    root: {
        marginTop: 16,
    },
    card: {

    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));


interface Props {
    hub: Hub;
}


export const HubDetailCard: React.FC<Props> =  ({ hub }: Props) => {
    const classes = useStyles();

    return (
            <Card className={classes.card}>
                <CardHeader avatar={
                    <Avatar>{hub.name.substr(0,1)}</Avatar>
                }
                            title={hub.name}
                            subheader={"Janitza Cloud Connector"}
                />
                <CardContent>
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          className={classes.root}>
                        {hub.balena ?
                            <>
                                <Grid item xs={8} lg={5} zeroMinWidth>
                                    <Typography color="textPrimary" noWrap className={classes.title}>
                                        Hub Type:
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} lg={5} zeroMinWidth>
                                    <Typography color="textPrimary" noWrap align={"right"} className={classes.title}>
                                        {hub.balena?.project}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} lg={5} zeroMinWidth>
                                    <Typography color="textPrimary" noWrap className={classes.title}>
                                        Hub State:
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} lg={5} zeroMinWidth>
                                    <Typography color="textPrimary" noWrap align={"right"} className={classes.title}>
                                        {hub.balena?.state}
                                    </Typography>
                                </Grid>
                            </>
                            : ""}
                        {hub.ipAddress ?
                            <>
                                <Grid item xs={8} lg={5} zeroMinWidth>
                                    <Typography color="textPrimary" noWrap className={classes.title}>
                                        Hub IP-Address:
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} lg={5} zeroMinWidth>
                                    <Typography color="textPrimary" noWrap align={"right"} className={classes.title}>
                                        {hub.ipAddress}
                                    </Typography>
                                </Grid>
                            </>
                            : ""}
                    </Grid>
                </CardContent>
            </Card>
        )
};
