import React, { useState } from 'react'
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {createStyles, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import * as firebase from "firebase/app";
import {User} from "firebase";
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(() =>
    createStyles( {
    root: {
        marginTop: 16,
    },
    card: {

    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}))
;

interface Props {
    match?: any;
}
export const GridVisSyncPage: React.FC<Props> = ({ match }: Props) => {
    const classes = useStyles();
    const [user] = useState<User | null>(() => {
        return firebase ? firebase.auth().currentUser : null
    });
    const userData = {
        uid: user?.uid,
        refreshToken: user?.refreshToken
    }
    return (
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={10}
                  className={classes.root}>
                <Grid item xs={11} lg={7}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Enter the following:
                            </Typography>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>

                                { JSON.stringify({
                                    user_id: userData.uid,
                                    refresh_token: userData.refreshToken,
                                    gridvis_id: uuidv4(),
                                    gridvis_url: "<URL>",
                                    gridvis_user: "<USER>",
                                    gridvis_password: "<PASSWORD>",
                                    id_token: "",
                                    id_expires: 0
                                }, null,2) }
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
};
