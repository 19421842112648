import React, { useState } from 'react'
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {CircularProgress, createStyles, makeStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import * as firebase from "firebase/app";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() =>
    createStyles( {
    root: {
        marginTop: 16,
    },
    card: {

    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}))
;

interface Props {
    match?: any;
}
export const ClaimPage: React.FC<Props> = ({ match }: Props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const functions = firebase.app().functions('europe-west1');
    const claimHubCall = functions.httpsCallable('original-claimHub');
    const [name, setName] = useState("");
    const [claiming, isClaiming] = useState(false);

    async function claimHub() {
        try {
            isClaiming(true);
            await claimHubCall({claimId: match.params.id, hubName: name});
            enqueueSnackbar("Hub successfully registered", { variant: 'success' });
        } catch(error) {
            enqueueSnackbar("Failed to register Hub", { variant: 'error' });
        }
        isClaiming(false);
    }

    return (
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={10}
                  className={classes.root}>
                <Grid item xs={11} lg={7}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Claim your GridVis Hub
                            </Typography>
                            <TextField id="outlined-name"
                                       disabled={ claiming }
                                       label="Give your Hub a Name"
                                       value={name}
                                       onChange={(event) => setName(event.target.value) }
                                       margin="normal"
                                       variant="outlined"/><br/>
                            <Button variant={"outlined"}
                                    disabled={ claiming }
                                    color={"primary"}
                                    onClick={ () => claimHub() }>Claim</Button>
                            { claiming && <CircularProgress size={22}/> }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
};
