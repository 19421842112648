import React, {useEffect, useState, Fragment} from 'react';
import * as firebase from "firebase/app";
import {BrowserRouter as Router, Link} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import {createStyles, makeStyles, MuiThemeProvider, Theme, useTheme} from '@material-ui/core/styles';
import 'typeface-roboto';
import './App.css';
import {SnackbarProvider} from 'notistack';
import {Frame, PrivateRoute} from "@janitza/firebase-ui";
import {ClaimPage} from "./component/ClaimPage";
import {GridVisSyncPage} from "./component/GridVisSyncPage";
import {HubsPage} from "./component/HubsPage";
import {blue, indigo} from "@material-ui/core/colors";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {User} from "firebase";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";

createMuiTheme({
    palette: {
        secondary: {
            main: blue[900]
        },
        primary: {
            main: indigo[700]
        }
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '"Lato"',
            'sans-serif'
        ].join(',')
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            margin: 10,
            width: 24,
            height: 24
        },
        logoutButton: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    }),

);
interface Props{
}

const App: React.FC<Props>= () => {
    const theme = useTheme();
    const [user, setUser] = useState<User|null>(() => { return firebase ? firebase.auth().currentUser : null});
    const classes = useStyles();
    const writeProfile = (email: string, userName: string, photoURL: string) => {
        console.log("Writing profile")
        firebase.app().firestore().doc(`/users/${firebase.app().auth().currentUser?.uid}`).set({
            email,
            displayName: userName
        }, { merge: true }).catch((e) => {console.log(e)})
    }

    const userInfo = (
        <Fragment>{user ? (
            <Grid container spacing={0} alignItems={"center"} justify={"flex-end"}>
                <Grid item>
                    <Avatar className={classes.avatar} alt={user.displayName||""} src={user.photoURL||""}/>
                </Grid>
                <Grid item className={classes.logoutButton}>
                    <Button color="inherit" onClick={() => firebase && firebase.auth().signOut()}>Logout</Button>
                </Grid>
            </Grid>
        ) : (
            <Link to={"/login"}>Login</Link>
    )}</Fragment>);
    useEffect(() => {
        return firebase ? firebase.auth().onAuthStateChanged((user) => setUser(user)) : () => {};
    }, []);
    return (
        <Router>
            <CssBaseline/>
            <MuiThemeProvider theme={theme}>
                <SnackbarProvider>
                    <Frame logout={() => {firebase && firebase.auth().signOut()}} userInfo={userInfo} title="Janitza&nbsp;Cloud">
                        <PrivateRoute path={"/"} exact component={HubsPage} loginCallback={writeProfile}/>
                        <PrivateRoute path="/claim/:id" component={ClaimPage} loginCallback={writeProfile}/>
                        <PrivateRoute path="/register/gridVisSync" component={GridVisSyncPage} loginCallback={writeProfile}/>
                    </Frame>
                </SnackbarProvider>
            </MuiThemeProvider>
        </Router>
    );
};

export {App};