import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from "firebase/app";
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import "firebase/performance";
import "firebase/remote-config";

const firebaseConfig = {
    apiKey: "AIzaSyDbi_lmmKgIjTtm977LizCk4hkKjol-3mw",
    authDomain: "gridvis-cloud-bd455.firebaseapp.com",
    databaseURL: "https://gridvis-cloud-bd455.firebaseio.com",
    projectId: "gridvis-cloud-bd455",
    storageBucket: "gridvis-cloud-bd455.appspot.com",
    messagingSenderId: "1073041339133",
    appId: "1:1073041339133:web:a21ab582270b9670438494",
    measurementId: "G-12Z48MKV8X"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore();
firebase.app().remoteConfig().fetchAndActivate().then(null);
const perf = firebase.performance();
perf.dataCollectionEnabled=true;
perf.instrumentationEnabled=true;

ReactDOM.render(
    <App/>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
